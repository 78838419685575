import React from 'react';
import { translate } from 'react-i18next';
import NavbarButton from '../../shared/NavbarButton';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { BLEEDING_EDGE_USERS, SERVICE_PERSONAL, getStyle } from '../../../utils/utils';

class VehicleDetailsNavbar extends React.Component {
  getActiveTab = () => {
    const { isUnpaidFleetUser } = this.props;
    const url = window.location.pathname;
    if (url.includes('/messages')) {
      return 'Message';
    } else if (url.includes('/tasks')) {
      return 'Task';
    } else if (url.includes('/treatments')) {
      return 'Treatment';
    } else if (url.includes('/maintenances')) {
      return 'Maintenance';
    } else if (url.includes('/sale_report')) {
      return 'Sale report';
    } else {
      return isUnpaidFleetUser ? 'Maintenance' : 'Basic';
    }
  };

  render() {
    const { t, vehicle, isUnpaidFleetUser, hasFeatureTasksAndMessages, service, user } = this.props;
    const activeTab = this.getActiveTab();

    const isReportDemoUser = user.is_staff || BLEEDING_EDGE_USERS.includes(user.email) || user.email.includes('ovio.fi');

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'navbarContainer')}>
            <div style={getStyle(screenSize, styles, 'buttonGroup')}>
              <NavbarButton
                to={`/vehicle/${vehicle.id}/basic`}
                iconSrc='/img/icon_car_basic_info.svg'
                alt='Basic'
                buttonText={t('basicInfoHeading')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
                disabled={isUnpaidFleetUser}
              />
              {vehicle.maintenances && (
                <NavbarButton
                  to={`/vehicle/${vehicle.id}/maintenances/`}
                  iconSrc='/img/icon_car_service.svg'
                  alt='Maintenance'
                  buttonText={t('maintenanceHistoryHeading')}
                  setActiveTab={tabName => this.setState({ activeTab: tabName })}
                  activeTab={activeTab}
                />
              )}
              {vehicle.treatments && (
                <NavbarButton
                  to={`/vehicle/${vehicle.id}/treatments`}
                  iconSrc='/img/icon_car_maintenance.svg'
                  alt='Treatment'
                  buttonText={t('treatmentHistoryHeading')}
                  setActiveTab={tabName => this.setState({ activeTab: tabName })}
                  activeTab={activeTab}
                />
              )}
              {vehicle.is_personal_vehicle && service === SERVICE_PERSONAL && (
                <NavbarButton
                  to={`/vehicle/${vehicle.id}/sale_report`}
                  iconSrc='/img/icon_vehicle_report.svg'
                  alt='Sale report'
                  buttonText={t('saleReportHeading')}
                  setActiveTab={tabName => this.setState({ activeTab: tabName })}
                  activeTab={activeTab}
                />
              )}
              {/*vehicle.inspections && (
                <NavbarButton
                  to={`/vehicle/${vehicle.id}/inspections`}
                  iconSrc='/img/icon_car_checkup.svg'
                  alt='Inspection'
                  buttonText={t('inspectionsHeading')}
                  setActiveTab={tabName => this.setState({ activeTab: tabName })}
                  activeTab={activeTab}
                />
              )*/}
              {!vehicle.is_personal_vehicle && hasFeatureTasksAndMessages && (
                <NavbarButton
                  to={`/vehicle/${vehicle.id}/messages`}
                  iconSrc='/img/icon_announcements.svg'
                  alt='Message'
                  buttonText={t('messagesHeading')}
                  setActiveTab={tabName => this.setState({ activeTab: tabName })}
                  activeTab={activeTab}
                />
              )}
              {!vehicle.is_personal_vehicle && hasFeatureTasksAndMessages && (
                <NavbarButton
                  to={`/vehicle/${vehicle.id}/tasks`}
                  iconSrc='/img/icon_task_list.svg'
                  alt='Task'
                  buttonText={t('tasksHeading')}
                  setActiveTab={tabName => this.setState({ activeTab: tabName })}
                  activeTab={activeTab}
                />
              )}
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    navbarContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    buttonGroup: {
      width: '70%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    navbarButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid #FFF',
      padding: 16,
      cursor: 'pointer',
    },
    buttonIcon: {
      display: 'inline-block',
      width: 64,
      height: 64,
    },
    buttonText: {
      display: 'inline',
      color: '#FFF',
      fontSize: 18,
    },
  },
  small: {
    buttonGroup: {
      width: '100%',
    },
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsNavbar);
