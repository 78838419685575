import React, { Component } from 'react';
import Modal from 'react-modal';
import CBMediaQuery from './CBMediaQuery';
import { getStyle, getModalStyle } from '../../utils/utils';
import CBButtonSubmit from './CBButtonSubmit';

class CBModal extends Component {
  render() {
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      title,
      descriptionText,
      hideCancelButton,
      vehicleText,
      cancelText,
      submitText,
      children,
      submitForm,
      onCancel,
      isValid,
      bothButtonsSubmitStyle
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <div style={getStyle(screenSize, styles, 'headerContainer')}>
                <img
                  src='/img/icon_close.svg'
                  alt='close'
                  style={getStyle(screenSize, styles, 'closeIcon')}
                  onClick={closeModal}
                  className="opacity-on-hover"
                />
                <p style={getStyle(screenSize, styles, 'title')}>
                  {title}
                </p>
                {
                  !!descriptionText && (
                    <p style={getStyle(screenSize, styles, 'headerText')}>
                      {descriptionText}
                    </p>
                  )
                }
                {
                  !!vehicleText && (
                    <p style={getStyle(screenSize, styles, 'headerTextVehicle')}>
                      {vehicleText}
                    </p>
                  )
                }
              </div>

              <div style={getStyle(screenSize, styles, 'contentContainer')}>
                {children}
              </div>
              <div style={getStyle(screenSize, styles, 'footerContainer')}>
                {
                  !hideCancelButton && (
                    <CBButtonSubmit
                      disabled={false}
                      onClick={onCancel || closeModal}
                      text={cancelText}
                      textStyle={bothButtonsSubmitStyle ? null : getStyle(screenSize, styles, 'cancelButtonText')}
                      style={getStyle(screenSize, styles, bothButtonsSubmitStyle ? 'submitButton' : 'cancelButton')}
                    />
                  )

                }
                <CBButtonSubmit
                  disabled={!isValid}
                  onClick={submitForm}
                  text={submitText}
                  style={getStyle(screenSize, styles, 'submitButton')}
                />
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    contentContainer: {
      overflowY: 'auto',
      maxHeight: '80vh',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingBottom: 100,
    },
    submitButton: {
      width: 200,
      border: '2px solid #0095da50',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginLeft: 16,
    },
    cancelButtonText: {
      fontSize: 14,
      fontWeight: 700,
      textDecoration: 'none solid rgb(0, 246, 0)',
      color: 'black',
      padding: 16,
      marginBottom: 0,
    },
    cancelButton: {
      width: 200,
      border: '2px solid #00000050',
      color: '#000000',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginLeft: 16,
    },
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      opacity: 0.2,
      cursor: 'pointer',
    },
    headerContainer: {
      padding: '20px 32px',
      //position: 'fixed',
      top: 0,
      width: '100%',
    },
    footerContainer: {
      width: '100%',
      bottom: 0,
      position: 'fixed',
      backgroundColor: '#FFF',
      display: 'flex',
      padding: '20px 32px',
      justifyContent: 'flex-end',
    },
    headerText: {
      color: 'black',
      fontSize: 16,
    },
    headerTextVehicle: {
      fontSize: 16,
      color: 'rgb(0, 149, 218)',
      fontFamily: 'TitilliumWeb-SemiBold',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
    },
  },
  small: {
    headerContainer: {
      padding: '20px 16px',
      //position: 'fixed',
      top: '0',
      width: '100%',
    },
    footerContainer: {
      width: '100%',
      bottom: 0,
      //position: 'fixed',
      display: 'flex',
      padding: '20px 16px',
      justifyContent: 'flex-end',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,

        overflow: 'hidden',
        padding: 0,
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '100%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

export default CBModal;
