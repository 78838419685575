import React from 'react';
import { getStyle, styleMerge } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSearchInput from '../../shared/CBSearchInput';

class VehicleDetailsSectionHeader extends React.Component {
  render() {
    const { text, subHeaderText, type, vehicle } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={styleMerge(getStyle(screenSize, styles, 'headerBar'), !!subHeaderText ? {} : getStyle(screenSize, styles, 'headerBarWithoutSubHeading'))}>
            <div style={getStyle(screenSize, styles, 'headingsContainer')}>
              <div style={getStyle(screenSize, styles, 'headerIconTextContainer')}>
                <img
                  alt='Heading'
                  style={getStyle(screenSize, styles, 'headerIcon')}
                  src='/img/icon_heading_marker-1.svg'
                />
                <div
                  className='font-titillium-web-extra-light'
                  style={getStyle(screenSize, styles, 'headerText')}
                >
                  {text}
                </div>
              </div>
              {
              !!subHeaderText && (
                  <div
                    className='font-titillium-web-extra-light'
                    style={getStyle(screenSize, styles, 'subHeaderText')}
                  >
                    {subHeaderText}
                  </div>
                )
              }
            </div>



            <div
              className='font-titillium-web'
              style={getStyle(screenSize, styles, 'boxContainer')}
            >
              {(type === 'maintenance' || type === 'treatment') && (
                <CBSearchInput vehicle={vehicle} type={type} />
              )}
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    box: {
      width: 115,
      display: 'flex',
      height: 30,
      opacity: 0.8,
      border: '1px solid white',
      borderRadius: 50,
      alignItems: 'center',
      marginBottom: 0,
    },
    headerBar: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 130,
    },
    headerBarWithoutSubHeading: {
      height: 110,
    },
    headerIcon: {
      width: 32,
      height: 32,
      marginRight: 16,
      color: 'red',
    },
    headerText: {
      fontSize: 32,
      fontWeight: 200,
      color: '#FFF',
      textTransform: 'uppercase',
    },
    subHeaderText: {
      fontSize: 18,
      fontWeight: 200,
      color: '#FFF',
      marginBottom: 16,
    },
    headerIconTextContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 0,
      marginBottom: 0,
    },
  },
  medium: {
    boxContainer: {
      width: 330,
      height: 30,
      marginBottom: 15,
      marginLeft: 0,
      paddingLeft: 0,
    },
    headerBar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingLeft: 15,
      paddingRight: 15,
      marginBottom: 31,
      height: 150,
    },
    headerIcon: {
      marginRight: 8,
    },
    headerIconTextContainer: {
      marginTop: 31,
      marginBottom: 8,
    },
    box: {
      marginBottom: 16,
    },
  },
  small: {
    boxContainer: {
      width: '100%',
    },
    headerBar: {
      marginBottom: 0,
    },
  }
};

export default VehicleDetailsSectionHeader;
