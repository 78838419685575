import { styleMerge } from "../../utils/utils"
import React from "react";

export default function CBSelectThumbnail({ src, onClick, selected }) {
    return (
        <div style={styleMerge(styles.container, selected ? styles.selected : {})} onClick={onClick}>
            <img style={styles.thumbnail} src={src} />
            {
                selected && (
                    <div className="checkmark"></div>
                )
            }
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: 5,
        margin: 5,
        width: 150,
        height: 150,
        backgroundColor: '#f9f9f9',
        position: 'relative',
    },
    selected: {
        border: '2px solid #0095da',
    },
    thumbnail: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
}