import React from 'react';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import OptionsButton from './OptionsComponents/OptionsButton';
import { deleteVehicleCategory as _deleteVehicleCategory } from '../../../actions/vehicleCategoryActions';
import AddCategoryModal from './AddCategoryModal';
import AddCarModal from './AddCarModal';

class VehicleCategoryHeader extends React.Component {
  ownCarsHeaderOptions = [
    {
      title: 'Lisää ajoneuvo',
      action: () => {
        this.addNewCar();
      },
    },
  ];

  generalHeaderOptions = [
    {
      title: 'Lisää ajoneuvo',
      action: () => {
        this.addNewCar();
      },
    },
    {
      title: 'Nimeä uudelleen',
      action: () => {
        this.updateCategory();
      },
    },
    {
      title: 'Poista',
      action: () => {
        const { category, deleteVehicleCategory } = this.props;
        deleteVehicleCategory(category.id);
      },
    },
  ];

  state = {
    modalIsOpen: false,
    newCarModalIsOpen: false,
  };

  addNewCar = () => {
    this.setState({
      newCarModalIsOpen: true,
    });
  };

  closeNewCarModal = () => {
    this.setState({
      newCarModalIsOpen: false,
    });
  };

  afterOpenNewCarModal = () => {};

  updateCategory = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  afterOpenModal = () => {};

  render() {
    const { category, user } = this.props;
    const { modalIsOpen, newCarModalIsOpen } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'headerBar')}>
            <div style={getStyle(screenSize, styles, 'headerLeft')}>
              <img
                alt='Heading'
                style={getStyle(screenSize, styles, 'headerIcon')}
                src='/img/icon_heading_marker-1.svg'
              />
              <p
                className='font-titillium-web-extra-light'
                style={getStyle(screenSize, styles, 'headerText')}
              >
                {category.name}
              </p>
            </div>
            <div style={getStyle(screenSize, styles, 'headerRight')}>
              <OptionsButton
                screenSize={screenSize}
                styles={styles}
                type='categoryHeader'
                options={
                  category.id === 'owned' || category.id === 'other'
                    ? this.ownCarsHeaderOptions
                    : this.generalHeaderOptions
                }
                icon='/img/more_icon.svg'
              />
            </div>
            <AddCategoryModal
              modalIsOpen={modalIsOpen}
              closeModal={this.closeModal}
              afterOpenModal={this.afterOpenModal}
              category={category}
            />
            <AddCarModal
              modalIsOpen={newCarModalIsOpen}
              closeModal={this.closeNewCarModal}
              afterOpenModal={this.afterOpenNewCarModal}
              category={category}
              user={user}
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    headerBar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerLeft: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    headerRight: {
      alignItems: 'center',
    },
    headerIcon: {
      width: 32,
      height: 32,
      marginRight: 16,
      color: 'red',
    },
    headerText: {
      fontSize: 22,
      fontWeight: 200,
      color: '#FFF',
      marginBottom: 0,
    },
    optionsIcon: {
      width: 25,
      height: 25,
    },
  },
  medium: {
    headerBar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 0,
    },
  },
  small: {
    headerBar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
};
function mapStateToProps(state) {
  return {
    vehicleCategoryRequest: state.vehicleCategory,
  };
}
export default connect(
  mapStateToProps,
  {
    deleteVehicleCategory: _deleteVehicleCategory,
  },
)(VehicleCategoryHeader);
