import React from 'react';
import { Route } from 'react-router';
import { translate } from 'react-i18next';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import VehicleDetailsTreatmentContent from './VehicleDetailsTreatmentContent';
import VehicleDetailsTreatmentList from './VehicleDetailsTreatmentList';

import CBMediaQuery from '../../shared/CBMediaQuery';

class VehicleDetailsMainTreatment extends React.Component {
  render() {
    const { t, vehicle } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <VehicleDetailsSectionHeader
              vehicle={vehicle}
              type="treatment"
              text={t('treatmentHistoryHeading')}
              subHeaderText={t('treatmentHistorySubHeading')}
            />
            {screenSize === 'small' && (
              <VehicleDetailsTreatmentList vehicle={vehicle} />
            )}
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && (
                <VehicleDetailsTreatmentList vehicle={vehicle} />
              )}
              <Route
                exact
                path="/vehicle/:vehicleId/treatments/"
                render={() => (
                  <VehicleDetailsTreatmentContent vehicle={vehicle} />
                )}
              />
              <Route
                path="/vehicle/:vehicleId/treatments/:treatmentId"
                render={props => (
                  <VehicleDetailsTreatmentContent
                    {...props}
                    vehicle={vehicle}
                  />
                )}
              />
              <Route
                exact
                path="/workshop-vehicle/:vehicleId/treatments/"
                render={() => (
                  <VehicleDetailsTreatmentContent vehicle={vehicle} />
                )}
              />
              <Route
                path="/workshop-vehicle/:vehicleId/treatments/:treatmentId"
                render={props => (
                  <VehicleDetailsTreatmentContent
                    {...props}
                    vehicle={vehicle}
                  />
                )}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainTreatment);
