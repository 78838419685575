import React from 'react';
import { Route, Redirect } from 'react-router';
import { translate } from 'react-i18next';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import VehicleDetailsMaintenanceContent from './VehicleDetailsMaintenanceContent';
import VehicleDetailsMaintenanceList from './VehicleDetailsMaintenanceList';
import CBMediaQuery from '../../shared/CBMediaQuery';

class VehicleDetailsMainMaintenance extends React.Component {
  render() {
    const { t, vehicle } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <div style={styles.headerContainer}>
              <VehicleDetailsSectionHeader
                vehicle={vehicle}
                type='maintenance'
                text={t('maintenanceInfoHeading')}
                subHeaderText={t('maintenanceInfoSubHeading')}
              />
            </div>
            {screenSize === 'small' && <VehicleDetailsMaintenanceList vehicle={vehicle} />}
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && <VehicleDetailsMaintenanceList vehicle={vehicle} />}
              <Route
                exact
                path='/vehicle/:vehicleId/'
                render={() => <VehicleDetailsMaintenanceContent vehicle={vehicle} />}
              />
              <Route
                exact
                path='/vehicle/:vehicleId/maintenances/'
                render={() => (vehicle.maintenances.length > 0
                  ? (<Redirect to={`${vehicle.maintenances[0].id}`} />)
                  : (<VehicleDetailsMaintenanceContent vehicle={vehicle} />))}
              />
              <Route
                path='/vehicle/:vehicleId/maintenances/:maintenanceId'
                render={props => <VehicleDetailsMaintenanceContent {...props} vehicle={vehicle} />}
              />
              <Route
                exact
                path='/workshop-vehicle/:vehicleId/'
                render={() => <VehicleDetailsMaintenanceContent vehicle={vehicle} />}
              />
              <Route
                exact
                path='/workshop-vehicle/:vehicleId/maintenances/'
                render={() => (vehicle.maintenances.length > 0
                  ? (<Redirect to={`${vehicle.maintenances[0].id}`} />)
                  : (<VehicleDetailsMaintenanceContent vehicle={vehicle} />))}
              />
              <Route
                path='/workshop-vehicle/:vehicleId/maintenances/:maintenanceId'
                render={props => <VehicleDetailsMaintenanceContent {...props} vehicle={vehicle} />}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainMaintenance);
