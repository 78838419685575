import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SubHeaderBar from '../shared/SubHeaderBar';
import CBHeaderTextWithHelp from '../shared/CBHeaderTextWithHelp';
import UserSettingsMenu from '../SettingsView/SettingsViewComponents/UserSettingsMenu';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import {
  fetchGlobalStats as _fetchGlobalStats,
} from '../../actions/globalStatsActions';
import CBSpinner from '../shared/CBSpinner';
class StaffStatsView extends React.Component {

  componentDidMount() {
    this.props.fetchGlobalStats()
  }

  componentWillMount() {
    document.documentElement.classList.add('settings-bg');
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('settings-bg');
  }

  render() {
    const { history, t, user, staff, globalStats } = this.props;

    if (globalStats.globalStatsStatus.loading) {
      return <CBSpinner />;
    }

    let tMaintenanceCount = 0;
    let tTreatmentCount = 0;
    let tRepairCount = 0;

    if (globalStats.current && globalStats.current.userCount) {
      const treatmentCountObj = globalStats.current.treatmentCount;

      const tMaintenanceCountObj = treatmentCountObj.find(m => m.treatment_type === 'maintenance');
      tMaintenanceCount = tMaintenanceCountObj ? tMaintenanceCountObj.count : 0;

      const tTreatmentCountObj = treatmentCountObj.find(tr => tr.treatment_type === 'treatment');
      tTreatmentCount = tTreatmentCountObj ? tTreatmentCountObj.count : 0;

      const tRepairCountObj = treatmentCountObj.find(r => r.treatment_type === 'repair');
      tRepairCount = tRepairCountObj ? tRepairCountObj.count : 0;
    }

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'statsViewContainer')}>
            <div style={getStyle(screenSize, styles, 'contentContainer')}>
              <CBHeaderTextWithHelp text={t('statsHeading')} />
              {globalStats.current && globalStats.current.userCount && (
                <React.Fragment>
                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfUsers')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current && globalStats.current.userCount && globalStats.current.userCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfVehicles')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.vehicleCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfOrganizationVehicles')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.organizationVehicleCount}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfCareServiceVehicles')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.careServiceVehicleCount}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfWorkshops')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.workshopCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfMaintenances')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.maintenanceCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfMaintenanceNotes')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.maintenanceNoteCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfMaintenanceParts')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.maintenancePartCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfMaintenanceTasks')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.maintenanceTaskCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfTreatmentTreatment')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {tTreatmentCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfTreatmentMaintenance')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {tMaintenanceCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      {t('statsNumberOfTreatmentRepair')}
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {tRepairCount.toLocaleString('fi-FI', {maximumFractionDigits:2})}
                    </p>
                  </div>

                  <CBHeaderTextWithHelp text={'Yksityispuolen myyntiraportit'} />
                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      Raportin luonti aloitettu
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.personal_sale_reports.reports_started}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      Raportteja luotu yhteensä
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.personal_sale_reports.reports_total}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      Aktiivisia raportteja
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.personal_sale_reports.reports_active}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      Raporttien myyntisivun katseluita
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.personal_sale_reports.reports_payment_viewed}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      Onnistuneesti ostettuja raportteja
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.personal_sale_reports.reports_payment_success}
                    </p>
                  </div>

                  <div style={styles.statItemContainer}>
                    <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                      Raporttien PDF-tiedoston latauksia
                    </p>
                    <p className='font-titillium-web-light' style={styles.infoValue}>
                      {globalStats.current.personal_sale_reports.reports_downloaded}
                    </p>
                  </div>





                </React.Fragment>
              )}
            </div>
            <div style={getStyle(screenSize, styles, 'contentContainer')}>
              <CBHeaderTextWithHelp text={t('workshopStatsHeading')} />
              {
                globalStats.workshops && globalStats.workshops.map((workshop) => (
                  <div style={getStyle(screenSize, styles, 'workshopStatsContainer')}>
                    <p style={getStyle(screenSize, styles, 'workshopLabel')}>
                      {workshop.name}
                    </p>
                    <div style={styles.statItemContainer}>
                      <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
                        {t('statsNumberOfMaintenances')}
                      </p>
                      <p className='font-titillium-web-light' style={styles.infoValue}>
                        {workshop.maintenanceCount}
                      </p>
                    </div>
                  </div>
                ))
              }

            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  infoLabel: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 600,
  },
  infoValue: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 200,
  },
  statItemContainer: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  default: {
    workshopLabel: {
      fontSize: 16,
    },
    workshopStatsContainer: {
      marginBottom: 16,
    },
    statsViewContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    contentContainer: {
      padding: 16,
      width: '50%',
    },
  },
  medium: {
    statsViewContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      width: '100%',
      padding: 0,
    },
  },
  small: {
    statsViewContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      width: '100%',
      padding: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    ...state,
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGlobalStats: _fetchGlobalStats,
  }
)(translate('StaffStatsView')(StaffStatsView));
