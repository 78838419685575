import React from 'react';
import { translate } from 'react-i18next';
import VehicleDetailsBasicDescription from './VehicleDetailsBasicDescription';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import CBMediaQuery from '../../shared/CBMediaQuery';
import VehicleDetailsSaleReportContent from './VehicleDetailsSaleReportContent';
import { getStyle } from '../../../utils/utils';

class VehicleDetailsMainSaleReport extends React.Component {
  render() {
    const { t } = this.props;
    const vehicle = this.props.vehicle;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <VehicleDetailsSectionHeader type='basic' text={t('saleReportHeading')} />
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && <div style={styles.leftSectionContainer} />}
              <VehicleDetailsSaleReportContent vehicle={vehicle} />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  leftSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(0, 149, 218, 0.4)',
    width: '25%',
    padding: 30,
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainSaleReport);
