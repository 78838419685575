import React from 'react';
import { translate } from 'react-i18next';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, formatDate } from '../../../utils/utils';
import CBButtonWithIcon from '../../shared/CBButtonWithIcon';
import PersonalSaleReportModal from './PersonalSaleReportModal';
import CBInputField from '../../shared/form_components/CBInputField';
import { getLengthValidator } from '../../../utils/validators';
import { toast } from 'react-toastify';
import { Button } from 'primereact/button';
import { deletePersonalSaleReport as _deletePersonalSaleReport } from '../../../actions/vehicleActions';
import { connect } from 'react-redux';
import CBPromptModal from '../../shared/CBPromptModal';

class VehicleDetailsSaleReportContent extends React.Component {
  state = {
    saleReportModalIsOpen: false,
    deleteModalIsOpen: false
  };

  copyUrlToClipboard = (text) => {
    const { t } = this.props;
    // Modern browsers with Clipboard API
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(function () {
        toast.success(t('SaleReport:linkCopied'));
      }).catch(function (err) {
        console.error('Could not copy text: ', err);
      });
    } else {
      // Fallback for older browsers
      var textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy');
        toast.success(t('SaleReport:linkCopied'));
      } catch (err) {
        console.error('Could not copy text', err);
      }

      document.body.removeChild(textarea);
    }
  }

  render() {
    const { t, vehicle, deletePersonalSaleReport } = this.props;
    const { saleReportModalIsOpen, deleteModalIsOpen } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div className='font-titillium-web' style={getStyle(screenSize, styles, 'mainContentContainer')}>
            <h2 style={getStyle(screenSize, styles, 'text')}>{t('VehicleDetailsView:saleReportHeading')}</h2>
            <p style={getStyle(screenSize, styles, 'text')}>{t('VehicleDetailsView:saleReportDescription')}</p>
            <p style={getStyle(screenSize, styles, 'text')}>{t('VehicleDetailsView:saleReportLinkDescription')}</p>
            <a style={getStyle(screenSize, styles, 'exampleLink')} className="font-titillium-web hover-underline" href="/static-server/carbook/pdf/sale-report.pdf">Katso esimerkkiraportti</a>
            {
              !vehicle.personal_sale_report_url && (
                <div className='opacity-on-hover' style={getStyle(screenSize, styles, 'buttonContainer')}>
                  <CBButtonWithIcon
                    onClick={() => { this.setState({ saleReportModalIsOpen: true }) }}
                    text={t('VehicleDetailsView:saleReportCreateButton')}
                    icon='/img/icon_vehicle_report.svg'
                  />
                </div>
              )
            }

            {
              vehicle.personal_sale_report_url && (
                <div>
                  <CBInputField
                    name='personal_sale_report_url'
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    value={vehicle.personal_sale_report_url}
                    placeholder=''
                    onChange={() => { }}
                    validators={[getLengthValidator(1, 500)]}
                    isValidCallback={() => true}
                    labelText={"Linkki myyntiraporttiin"}
                    labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                    disabled={true}
                  />
                  <div style={getStyle(screenSize, styles, 'reportButtonContainer')}>
                    <CBButtonWithIcon icon='/img/icon_vehicle_report.svg' text={t('SaleReport:downloadPdf')} onClick={() => window.open(vehicle.personal_sale_report_url)} />
                    <CBButtonWithIcon icon='/img/copy.svg' text={t('SaleReport:copyLink')} onClick={() => { this.copyUrlToClipboard(vehicle.personal_sale_report_url) }} />
                    <CBButtonWithIcon icon='/img/icon_edit.svg' text={t('SaleReport:editReport')} onClick={() => { this.setState({saleReportModalIsOpen: true}) }} />
                    <CBButtonWithIcon icon='/img/icon_trashcan.svg' text={t('SaleReport:deleteReport')} onClick={() => { this.setState({deleteModalIsOpen: true}) }} />
                  </div>
                </div>
              )
            }
            {
              saleReportModalIsOpen && (
                <PersonalSaleReportModal
                  modalIsOpen={saleReportModalIsOpen}
                  closeModal={() => { this.setState({ saleReportModalIsOpen: false }) }}
                  afterOpenModal={() => { }}
                  t={t}
                  vehicle={vehicle}
                />
              )
            }
            {
              deleteModalIsOpen && (
                <CBPromptModal
                  modalIsOpen={deleteModalIsOpen}
                  closeModal={() => { this.setState({deleteModalIsOpen: false}) }}
                  afterOpenModal={() => {}}
                  title={t('SaleReport:deletePersonalSaleReportTitle')}
                  text={t('SaleReport:deletePersonalSaleReportText')}
                  buttonText={t('SaleReport:deletePersonalSaleReportButton')}
                  performAction={() => {deletePersonalSaleReport(vehicle.id); this.setState({deleteModalIsOpen: false});}}
                />
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    text: {
      color: '#ffffff',
    },
    exampleLink: {
      color: '#ffffff',
      fontSize: 18,
      marginBottom: 16,
      marginTop: 16

    },
    mainContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
      padding: 16,
    },
    buttonContainer: {
      width: 200,
      marginTop: 8
    },
    reportButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 16,
      marginTop: 16,
    },
    input: {
      backgroundColor: '#ffffff',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: '#ffffff',
    },
  },
  small: {
    mainContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '100%',
    },
    reportButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 16,
      marginTop: 16,
    },
  },
};

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  {
    deletePersonalSaleReport: _deletePersonalSaleReport,
  },
)(translate()(VehicleDetailsSaleReportContent));
