import { vehicleState } from '../initialState';
import { storeInsertItem, storeInsertItems, storeRemoveItem, storeRemoveItems, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = vehicleState, action) {
  switch (action.type) {
    case 'FETCH_GARAGE_VEHICLES_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_GARAGE_VEHICLES_SUCCESS': {
      return {
        ...state,
        garageVehicles: action.payload,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_GARAGE_VEHICLES_FAIL': {
      return {
        ...state,
        garageVehicles: [],
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_ORGANIZATION_VEHICLES_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_ORGANIZATION_VEHICLES_SUCCESS': {
      return {
        ...state,
        garageVehicles: action.payload,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_ORGANIZATION_VEHICLES_FAIL': {
      return {
        ...state,
        garageVehicles: [],
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_CARE_SERVICE_VEHICLES_REQUEST': {
      return {
        ...state,
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_CARE_SERVICE_VEHICLES_SUCCESS': {
      return {
        ...state,
        careServiceVehicles: action.payload,
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_CARE_SERVICE_VEHICLES_FAIL': {
      return {
        ...state,
        careServiceVehicles: [],
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'ADD_CARE_SERVICE_VEHICLE_REQUEST': {
      return {
        ...state,
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'ADD_CARE_SERVICE_VEHICLE_SUCCESS': {
      return {
        ...state,
        careServiceVehicles: storeInsertItem(state.careServiceVehicles, action.payload),
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'ADD_CARE_SERVICE_VEHICLE_FAIL': {
      return {
        ...state,
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'REMOVE_CARE_SERVICE_VEHICLE_WORKSHOP_REQUEST': {
      return {
        ...state,
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'REMOVE_CARE_SERVICE_VEHICLE_WORKSHOP_SUCCESS': {
      return {
        ...state,
        careServiceVehicles: storeRemoveItem(state.careServiceVehicles, action.payload.id),
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'REMOVE_CARE_SERVICE_VEHICLE_WORKSHOP_FAIL': {
      return {
        ...state,
        careServiceVehicleStatus: Object.assign({}, state.careServiceVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'REMOVE_CARE_SERVICE_VEHICLE_FLEET_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'REMOVE_CARE_SERVICE_VEHICLE_FLEET_SUCCESS': {
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, action.payload),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'REMOVE_CARE_SERVICE_VEHICLE_FLEET_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'REMOVE_VEHICLE_FROM_GARAGE_SUCCESS': {
      let newVehicles;

      if (action.payload.vehicle_id) {
        newVehicles = storeRemoveItem(state.garageVehicles, action.payload.vehicle_id);
      } else if (action.payload.vehicle_ids) {
        newVehicles = storeRemoveItems(state.garageVehicles, action.payload.vehicle_ids);
      }

      return {
        ...state,
        garageVehicles: newVehicles,
      };
    }
    case 'CREATE_ORGANIZATION_VEHICLE_STATUS_REQUEST': {
      return {
        ...state,
        orgVehicleStatusStatus: Object.assign({}, state.orgVehicleStatusStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'CREATE_ORGANIZATION_VEHICLE_STATUS_SUCCESS': {
      const oldVehicle = state.garageVehicles.find(gv => (gv.id === action.payload.vehicle_id));
      const newVehicle = Object.assign(
        {}, oldVehicle, { organization_vehicle_status: action.payload },
      );
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, newVehicle),
        orgVehicleStatusStatus: Object.assign({}, state.orgVehicleStatusStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_ORGANIZATION_VEHICLE_STATUS_FAIL': {
      return {
        ...state,
        orgVehicleStatusStatus: Object.assign({}, state.orgVehicleStatusStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_VEHICLE_REQUEST': {
      return {
        ...state,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'UPDATE_VEHICLE_SUCCESS': {
      return {
        ...state,
        vehicle: action.payload,
        vehicles: storeInsertItem(state.vehicles, action.payload),
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'UPDATE_VEHICLE_FAIL': {
      return {
        ...state,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'FETCH_VEHICLES_REQUEST': {
      return {
        ...state,
        vehicles: action.payload,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLES_SUCCESS': {
      return {
        ...state,
        vehicles: action.payload,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLES_FAIL': {
      return {
        ...state,
        vehicles: [],
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_WS_VEHICLES_REQUEST': {
      return {
        ...state,
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_WS_VEHICLES_SUCCESS': {
      return {
        ...state,
        wsVehicles: action.payload.results,
        wsVehiclePagination: action.payload,
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_WS_VEHICLES_NEXT_PAGE_SUCCESS': {
      return {
        ...state,
        wsVehicles: state.wsVehicles.concat(action.payload.results),
        wsVehiclePagination: action.payload,
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_WS_VEHICLES_FAIL': {
      return {
        ...state,
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'CREATE_VEHICLE_REQUEST': {
      return {
        ...state,
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'CREATE_VEHICLE_SUCCESS': {
      return {
        ...state,
        wsVehicles: storeInsertItem(state.wsVehicles, action.payload),
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_VEHICLE_FAIL': {
      return {
        ...state,
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'UPDATE_WS_VEHICLE_REQUEST': {
      return {
        ...state,
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'UPDATE_WS_VEHICLE_SUCCESS': {
      const oldWsVehicle = state.wsVehicle;
      let wsVehicle = oldWsVehicle;
      if (oldWsVehicle && oldWsVehicle.id === action.payload.id) {
        wsVehicle = action.payload;
      }
      return {
        ...state,
        wsVehicle,
        wsVehicles: storeUpdateObjectInArray(state.wsVehicles, action.payload),
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'UPDATE_WS_VEHICLE_FAIL': {
      return {
        ...state,
        wsVehicleStatus: Object.assign({}, state.wsVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'FETCH_VEHICLE_REQUEST': {
      return {
        ...state,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'FETCH_VEHICLE_SUCCESS': {
      return {
        ...state,
        vehicle: action.payload,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLE_FAIL': {
      return {
        ...state,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_WS_VEHICLE_REQUEST': {
      return {
        ...state,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_WS_VEHICLE_SUCCESS': {
      return {
        ...state,
        wsVehicle: action.payload,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_WS_VEHICLE_FAIL': {
      return {
        ...state,
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CLEAR_CURRENT_VEHICLE': {
      return {
        ...state,
        vehicle: null,
      };
    }
    case 'CLEAR_WS_VEHICLES': {
      return {
        ...state,
        wsVehicles: [],
      };
    }
    case 'FETCH_MAINTENANCE_REQUEST': {
      return {
        ...state,
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_MAINTENANCE_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          maintenances: storeUpdateObjectInArray(state.vehicle.maintenances, action.payload),
        },
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_MAINTENANCE_FAIL': {
      return {
        ...state,
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_VEHICLE_MAINTENANCE_REQUEST': {
      return {
        ...state,
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_MAINTENANCE_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          maintenances: storeInsertItem(state.vehicle.maintenances, action.payload),
        },
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_MAINTENANCE_FAIL': {
      return {
        ...state,
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_VEHICLE_MAINTENANCE_MESSAGE_REQUEST': {
      return {
        ...state,
        maintenanceMessageStatus: Object.assign({}, state.maintenanceMessageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_MAINTENANCE_MESSAGE_SUCCESS': {
      return {
        ...state,
        maintenanceMessageStatus: Object.assign({}, state.maintenanceMessageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_MAINTENANCE_MESSAGE_FAIL': {
      return {
        ...state,
        maintenanceMessageStatus: Object.assign({}, state.maintenanceMessageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_VEHICLE_MAINTENANCE_REQUEST': {
      return {
        ...state,
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_MAINTENANCE_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          maintenances: storeUpdateObjectInArray(state.vehicle.maintenances, action.payload),
        },
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_MAINTENANCE_FAIL': {
      return {
        ...state,
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_VEHICLE_MAINTENANCE_REQUEST': {
      return {
        ...state,
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_MAINTENANCE_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          maintenances: storeRemoveItem(state.vehicle.maintenances, action.payload.id),
        },
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_MAINTENANCE_FAIL': {
      return {
        ...state,
        maintenanceStatus: Object.assign({}, state.maintenanceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_MAINTENANCE_IMAGE_REQUEST': {
      return {
        ...state,
        maintenanceImageStatus: Object.assign({}, state.maintenanceImageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_MAINTENANCE_IMAGE_SUCCESS': {
      const maintenance = state.vehicle.maintenances.find(t => t.id === action.payload.maintenance);
      const newMaintenance = Object.assign({}, maintenance);
      const newImage = {
        id: action.payload.id,
        image: action.payload.image,
      };
      newMaintenance.images = [...newMaintenance.images, newImage];
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          maintenances: storeUpdateObjectInArray(state.vehicle.maintenances, newMaintenance),
        },
        maintenanceImageStatus: Object.assign({}, state.maintenanceImageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_MAINTENANCE_IMAGE_FAIL': {
      return {
        ...state,
        maintenanceImageStatus: Object.assign({}, state.maintenanceImageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'DELETE_MAINTENANCE_IMAGE_REQUEST': {
      return {
        ...state,
        maintenanceImageStatus: Object.assign({}, state.maintenanceImageStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'DELETE_MAINTENANCE_IMAGE_SUCCESS': {
      const maintenance = state.vehicle.maintenances.find(t => t.id === action.payload.maintenance);
      const newMaintenance = Object.assign({}, maintenance);
      newMaintenance.images = storeRemoveItem(newMaintenance.images, action.payload.id);
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          maintenances: storeUpdateObjectInArray(state.vehicle.maintenances, newMaintenance),
        },
        maintenanceImageStatus: Object.assign({}, state.maintenanceImageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'DELETE_MAINTENANCE_IMAGE_FAIL': {
      return {
        ...state,
        maintenanceImageStatus: Object.assign({}, state.maintenanceImageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_TREATMENT_REQUEST': {
      return {
        ...state,
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_TREATMENT_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          treatments: storeUpdateObjectInArray(state.vehicle.treatments, action.payload),
        },
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_TREATMENT_FAIL': {
      return {
        ...state,
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_VEHICLE_TREATMENT_REQUEST': {
      return {
        ...state,
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_TREATMENT_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          treatments: storeInsertItem(state.vehicle.treatments, action.payload),
        },
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_TREATMENT_FAIL': {
      return {
        ...state,
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_VEHICLE_TREATMENT_REQUEST': {
      return {
        ...state,
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_TREATMENT_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          treatments: storeUpdateObjectInArray(state.vehicle.treatments, action.payload),
        },
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_TREATMENT_FAIL': {
      return {
        ...state,
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_VEHICLE_TREATMENT_REQUEST': {
      return {
        ...state,
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_TREATMENT_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          treatments: storeRemoveItem(state.vehicle.treatments, action.payload.id),
        },
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_TREATMENT_FAIL': {
      return {
        ...state,
        treatmentStatus: Object.assign({}, state.treatmentStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_INSPECTION_REQUEST': {
      return {
        ...state,
        inspectionStatus: Object.assign({}, state.inspectionStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_INSPECTION_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          inspections: storeUpdateObjectInArray(state.vehicle.inspections, action.payload),
        },
        inspectionStatus: Object.assign({}, state.inspectionStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_INSPECTION_FAIL': {
      return {
        ...state,
        inspectionStatus: Object.assign({}, state.inspectionStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_VEHICLE_DESCRIPTION_REQUEST': {
      return {
        ...state,
        descriptionStatus: Object.assign({}, state.descriptionStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_DESCRIPTION_REQUEST': {
      return {
        ...state,
        descriptionStatus: Object.assign({}, state.descriptionStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_DESCRIPTION_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          description: { ...state.vehicle.description, description: action.payload.description },
        },
        descriptionStatus: Object.assign({}, state.descriptionStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_DESCRIPTION_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          description: { ...state.vehicle.description, description: action.payload.description },
        },
        descriptionStatus: Object.assign({}, state.descriptionStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_DESCRIPTION_FAIL': {
      return {
        ...state,
        descriptionStatus: Object.assign({}, state.descriptionStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_VEHICLE_DESCRIPTION_FAIL': {
      return {
        ...state,
        descriptionStatus: Object.assign({}, state.descriptionStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'CREATE_VEHICLE_IMAGE_REQUEST': {
      return {
        ...state,
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_IMAGE_SUCCESS': {
      const newImages = [...state.vehicle.images, action.payload];
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          images: newImages,
        },
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_VEHICLE_IMAGE_FAIL': {
      return {
        ...state,
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'UPDATE_VEHICLE_IMAGE_REQUEST': {
      return {
        ...state,
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_IMAGE_SUCCESS': {
      let newImages = state.vehicle.images.map((image) => Object.assign({}, image, { is_default: false }));
      newImages = storeRemoveItem(newImages, action.payload);
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          images: storeInsertItem(newImages, action.payload),
        },
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_IMAGE_FAIL': {
      return {
        ...state,
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'DELETE_VEHICLE_IMAGE_REQUEST': {
      return {
        ...state,
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'DELETE_VEHICLE_IMAGE_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          images: storeRemoveItem(state.vehicle.images, action.payload.id),
        },
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'DELETE_VEHICLE_IMAGE_FAIL': {
      return {
        ...state,
        imageStatus: Object.assign({}, state.imageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'CREATE_TREATMENT_IMAGE_REQUEST': {
      return {
        ...state,
        treatmentImageStatus: Object.assign({}, state.treatmentImageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_TREATMENT_IMAGE_SUCCESS': {
      const treatment = state.vehicle.treatments.find(t => t.id === action.payload.treatment);
      const newTreatment = Object.assign({}, treatment);
      const newImage = {
        id: action.payload.id,
        image: action.payload.image,
      };
      newTreatment.images = [...newTreatment.images, newImage];
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          treatments: storeUpdateObjectInArray(state.vehicle.treatments, newTreatment),
        },
        treatmentImageStatus: Object.assign({}, state.treatmentImageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_TREATMENT_IMAGE_FAIL': {
      return {
        ...state,
        treatmentImageStatus: Object.assign({}, state.treatmentImageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'DELETE_TREATMENT_IMAGE_REQUEST': {
      return {
        ...state,
        treatmentImageStatus: Object.assign({}, state.treatmentImageStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'DELETE_TREATMENT_IMAGE_SUCCESS': {
      const treatment = state.vehicle.treatments.find(t => t.id === action.payload.treatment);
      const newTreatment = Object.assign({}, treatment);
      newTreatment.images = storeRemoveItem(newTreatment.images, action.payload.id);
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          treatments: storeUpdateObjectInArray(state.vehicle.treatments, newTreatment),
        },
        treatmentImageStatus: Object.assign({}, state.treatmentImageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'DELETE_TREATMENT_IMAGE_FAIL': {
      return {
        ...state,
        treatmentImageStatus: Object.assign({}, state.treatmentImageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'CREATE_VEHICLE_REMINDER_REQUEST': {
      return {
        ...state,
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_REMINDER_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          reminders: storeInsertItem(state.vehicle.reminders, action.payload),
        },
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_REMINDER_FAIL': {
      return {
        ...state,
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_VEHICLE_REMINDER_REQUEST': {
      return {
        ...state,
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_REMINDER_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          reminders: storeUpdateObjectInArray(state.vehicle.reminders, action.payload),
        },
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_REMINDER_FAIL': {
      return {
        ...state,
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_VEHICLE_REMINDER_REQUEST': {
      return {
        ...state,
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_REMINDER_SUCCESS': {
      const vehicle = state.vehicle
        ? {
          ...state.vehicle,
          reminders: storeRemoveItem(state.vehicle.reminders, action.payload.id),
        }
        : { ...state.vehicle };
      return {
        ...state,
        vehicle,
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_REMINDER_FAIL': {
      return {
        ...state,
        reminderStatus: Object.assign({}, state.reminderStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_VEHICLE_PERMISSION_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_VEHICLE_PERMISSION_SUCCESS': {
      const vehicleId = action.payload.vehicle;
      const vehicle = state.garageVehicles.find((x) => x.id === vehicleId);
      const newVehicle = { ...vehicle };
      newVehicle.permissions = storeUpdateObjectInArray(newVehicle.permissions, action.payload);
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, newVehicle),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_VEHICLE_PERMISSION_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_VEHICLE_PERMISSION_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_VEHICLE_PERMISSION_SUCCESS': {
      const vehicleId = action.additionalData.vehicleId;
      const vehicle = state.garageVehicles.find((x) => x.id === vehicleId);
      const newVehicle = { ...vehicle };
      newVehicle.permissions = storeRemoveItem(newVehicle.permissions, action.additionalData.permissionId);
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, newVehicle),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_VEHICLE_PERMISSION_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_VEHICLE_PERMISSION_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_VEHICLE_PERMISSION_SUCCESS': {
      const vehicleId = action.payload.vehicle;
      const vehicle = state.garageVehicles.find((x) => x.id === vehicleId);
      const newVehicle = { ...vehicle };
      newVehicle.permissions = storeInsertItem(newVehicle.permissions, action.payload);
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, newVehicle),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_VEHICLE_PERMISSION_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'TOGGLE_VEHICLE_ORGANIZATION_SHARING_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'TOGGLE_VEHICLE_ORGANIZATION_SHARING_SUCCESS': {
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, action.payload),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'TOGGLE_VEHICLE_ORGANIZATION_SHARING_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'REQUEST_GARAGE_VEHICLE_PERMISSION_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'REQUEST_GARAGE_VEHICLE_PERMISSION_SUCCESS': {
      return {
        ...state,
        garageVehicles: storeInsertItem(state.garageVehicles, action.payload),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'REQUEST_GARAGE_VEHICLE_PERMISSION_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_MESSAGE_REQUEST': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_MESSAGE_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, action.payload),
        },
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_MESSAGE_FAIL': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_VEHICLE_MESSAGE_REQUEST': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_MESSAGE_SUCCESS': {
      const messages = Array.isArray(action.payload)
        ? storeInsertItems(state.vehicle.messages, action.payload)
        : storeInsertItem(state.vehicle.messages, action.payload);

      let newTasks = [...state.vehicle.tasks];
      if (!Array.isArray(action.payload) && action.payload.task) {
        newTasks = storeInsertItem(state.vehicle.tasks, action.payload.task);
      }

      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: messages,
          tasks: newTasks,
        },
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_MESSAGE_FAIL': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_VEHICLE_MESSAGE_REQUEST': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_MESSAGE_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, action.payload),
        },
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_MESSAGE_FAIL': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_VEHICLE_MESSAGE_REQUEST': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_MESSAGE_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeRemoveItem(state.vehicle.messages, action.payload.id),
        },
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_MESSAGE_FAIL': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_MESSAGE_IMAGE_REQUEST': {
      return {
        ...state,
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_MESSAGE_IMAGE_SUCCESS': {
      const message = state.vehicle.messages.find(t => t.id === action.payload.message);
      const newMessage = Object.assign({}, message);
      const newImage = {
        id: action.payload.id,
        image: action.payload.image,
      };
      newMessage.images = [...newMessage.images, newImage];
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, newMessage),
        },
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_MESSAGE_IMAGE_FAIL': {
      return {
        ...state,
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'DELETE_MESSAGE_IMAGE_REQUEST': {
      return {
        ...state,
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'DELETE_MESSAGE_IMAGE_SUCCESS': {
      const message = state.vehicle.messages.find(t => t.id === action.payload.message);
      const newMessage = Object.assign({}, message);
      newMessage.images = storeRemoveItem(newMessage.images, action.payload.id);
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, newMessage),
        },
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'DELETE_MESSAGE_IMAGE_FAIL': {
      return {
        ...state,
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'CREATE_VEHICLE_MESSAGE_RESPONSE_REQUEST': {
      return {
        ...state,
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'CREATE_VEHICLE_MESSAGE_RESPONSE_SUCCESS': {
      const messageId = action.payload.message;
      const message = state.vehicle.messages.find(t => t.id == messageId);
      const newMessage = Object.assign({}, message);
      newMessage.responses.push(action.payload);
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, newMessage),
        },
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_VEHICLE_MESSAGE_RESPONSE_FAIL': {
      return {
        ...state,
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'UPDATE_VEHICLE_MESSAGE_RESPONSE_REQUEST': {
      return {
        ...state,
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'UPDATE_VEHICLE_MESSAGE_RESPONSE_SUCCESS': {
      const messageId = action.payload.message;
      const message = state.vehicle.messages.find(t => t.id == messageId);
      const newMessage = Object.assign({}, message);
      newMessage.responses = storeUpdateObjectInArray(newMessage.responses, action.payload);
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, newMessage),
        },
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'UPDATE_VEHICLE_MESSAGE_RESPONSE_FAIL': {
      return {
        ...state,
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'DELETE_VEHICLE_MESSAGE_RESPONSE_REQUEST': {
      return {
        ...state,
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'DELETE_VEHICLE_MESSAGE_RESPONSE_SUCCESS': {
      const messageId = action.payload.message;
      const message = state.vehicle.messages.find(t => t.id == messageId);
      const newMessage = Object.assign({}, message);
      newMessage.responses = storeRemoveItem(newMessage.responses, action.payload.id);
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, newMessage),
        },
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'DELETE_VEHICLE_MESSAGE_RESPONSE_FAIL': {
      return {
        ...state,
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'CREATE_MESSAGE_RESPONSE_IMAGE_REQUEST': {
      return {
        ...state,
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_MESSAGE_RESPONSE_IMAGE_SUCCESS': {
      const message = state.vehicle.messages.find(t => t.id === action.additionalData.messageId);
      const newMessage = Object.assign({}, message);

      const newResponse = newMessage.responses.find(t => t.id === action.payload.message_response);

      const newImage = {
        id: action.payload.id,
        image: action.payload.image,
      };

      newResponse.images = [...newResponse.images, newImage];

      newMessage.responses = storeUpdateObjectInArray(newMessage.responses, newResponse);

      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, newMessage),
        },
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_MESSAGE_RESPONSE_IMAGE_FAIL': {
      return {
        ...state,
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'DELETE_MESSAGE_RESPONSE_IMAGE_REQUEST': {
      return {
        ...state,
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'DELETE_MESSAGE_RESPONSE_IMAGE_SUCCESS': {
      const message = state.vehicle.messages.find(t => t.id === action.additionalData.messageId);
      const newMessage = Object.assign({}, message);

      const newResponse = newMessage.responses.find(t => t.id === action.payload.message_response);

      newResponse.images = storeRemoveItem(newResponse.images, action.payload.id);

      newMessage.responses = storeUpdateObjectInArray(newMessage.responses, newResponse);

      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          messages: storeUpdateObjectInArray(state.vehicle.messages, newMessage),
        },
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'DELETE_MESSAGE_RESPONSE_IMAGE_FAIL': {
      return {
        ...state,
        messageImageStatus: Object.assign({}, state.messageImageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'TOGGLE_VEHICLE_MESSAGE_SUBSCRIPTION_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'TOGGLE_VEHICLE_MESSAGE_SUBSCRIPTION_SUCCESS': {
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, action.payload),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'TOGGLE_VEHICLE_MESSAGE_SUBSCRIPTION_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'ATTACH_VEHICLE_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'ATTACH_VEHICLE_SUCCESS': {
      let parent = null;

      let newGarageVehicles = [...state.garageVehicles];

      if (action.payload.parent) {
        parent = state.garageVehicles.find((gVehicle) => action.payload.parent && gVehicle.id == action.payload.parent.id);
        const child = parent.children.find((c) => c.id == action.payload.id);
        if (!child) {
          parent.children = storeInsertItem(parent.children, action.payload);
          newGarageVehicles = storeUpdateObjectInArray(newGarageVehicles, parent);
        }
      } else {
        parent = state.garageVehicles.find((gVehicle) => action.additionalData.parent.id == gVehicle.id);
        parent.children = storeRemoveItem(parent.children, action.payload.id);
        newGarageVehicles = storeUpdateObjectInArray(newGarageVehicles, parent);
      }

      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(newGarageVehicles, action.payload),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'ATTACH_VEHICLE_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_VEHICLE_TASK_REQUEST': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLE_TASK_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          tasks: storeUpdateObjectInArray(state.vehicle.tasks, action.payload),
        },
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLE_TASK_FAIL': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_VEHICLE_TASK_REQUEST': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_TASK_SUCCESS': {
      if (action.payload.vehicle_ids) {
        return {
          ...state,
          taskStatus: Object.assign({}, state.taskStatus, {
            loading: false,
            error: null,
          }),
        };
      }
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          tasks: storeInsertItem(state.vehicle.tasks, action.payload),
        },
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_TASK_FAIL': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_VEHICLE_TASK_REQUEST': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_TASK_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          tasks: storeUpdateObjectInArray(state.vehicle.tasks, action.payload),
        },
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_TASK_FAIL': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_VEHICLE_TASK_REQUEST': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_TASK_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          tasks: storeRemoveItem(state.vehicle.tasks, action.payload.id),
        },
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_TASK_FAIL': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_SALE_REPORT_SUBSCRIPTION_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'CREATE_SALE_REPORT_SUBSCRIPTION_SUCCESS': {
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, action.payload),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_SALE_REPORT_SUBSCRIPTION_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'SET_MANUAL_KM_REQUEST': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'SET_MANUAL_KM_SUCCESS': {
      return {
        ...state,
        garageVehicles: storeUpdateObjectInArray(state.garageVehicles, action.payload),
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'SET_MANUAL_KM_FAIL': {
      return {
        ...state,
        garageVehicleStatus: Object.assign({}, state.garageVehicleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_PERSONAL_SALE_REPORT_REQUEST': {
      return {
        ...state,
      };
    }

    case 'UPDATE_PERSONAL_SALE_REPORT_SUCCESS': {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          personal_sale_report_url: action.payload.personal_sale_report_url,
          personal_sale_report_subscription: action.payload.personal_sale_report_subscription,
        },
        vehicleStatus: Object.assign({}, state.vehicleStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'UPDATE_PERSONAL_SALE_REPORT_FAIL': {
      if (action.payload.vehicle) {
        return {
          ...state,
          vehicle: {
            ...state.vehicle,
            personal_sale_report_url: action.payload.vehicle.personal_sale_report_url,
            personal_sale_report_subscription: action.payload.vehicle.personal_sale_report_subscription,
          },
          vehicleStatus: Object.assign({}, state.vehicleStatus, {
            loading: false,
            error: null,
          }),
        };
      }

      return {
        ...state,
      }

    }
    default:
      break;
  }
  return state;
}
