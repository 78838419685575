import React, { Component } from 'react';

class CBButtonWithIcon extends Component {
  onKeyUp = event => {
    const { onClick } = this.props;
    event.preventDefault();
    if (event.keyCode === 13) {
      onClick();
    }
  };

  render() {
    const { onClick, text, icon } = this.props;
    return (
      <div
        className='opacity-and-border-on-hover'
        style={styles.container}
        onClick={onClick}
        role='button'
        tabIndex={0}
        onKeyUp={this.onKeyUp}
      >
        <img src={icon} alt={text} style={styles.icon} />
        <p style={styles.label}>
          {text}
        </p>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(255,255,255,.3)',
    borderRadius: 50,
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    cursor: 'pointer',
  },
  icon: {
    height: 35,
    width: 35,
  },
  label: {
    fontSize: 14,
    margin: 0,
    marginLeft: 10,
  },
};

export default CBButtonWithIcon;
