import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { finnishDateFormat, getStyle, SERVICE_FLEET, SERVICE_WORKSHOP, dbDateFormat, formatDate } from '../../../utils/utils';
import CBModal from '../../shared/CBModal';
import { createPersonalSaleReport as _createPersonalSaleReport } from '../../../actions/vehicleActions';
import { toast } from 'react-toastify';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import CBSelectThumbnail from '../../shared/CBSelectThumbnail';
import Axios from 'axios';


class PersonalSaleReportModal extends Component {
  state = {
    step: 'intro',
    selectedImage: null,
    selectedTreatments: [],
    token: null,
  };

  constructor(props) {
    super(props);
  };

  componentDidMount = () => {
    const { vehicle } = this.props;
    if (vehicle.personal_sale_report_subscription) {
      this.setState({
        selectedImage: vehicle.personal_sale_report_subscription.image_id,
        selectedTreatments: vehicle.personal_sale_report_subscription.treatment_ids,
        token: vehicle.personal_sale_report_subscription.token
      })
    } else {
      if (vehicle.images.length > 0) {
        const defaultImage = vehicle.images.find(image => image.is_default);
        this.setState({
          selectedImage: defaultImage ? defaultImage.id : vehicle.images[0].id,
        })
      }

      this.addSaleReportCreationOpenedEvent();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { vehicle } = this.props;
  };

  addSaleReportCreationOpenedEvent = async () => {
    const { vehicle } = this.props;
    await Axios.post(`/uiapi/1/vehicle/${vehicle.id}/personal_sale_report_started/`)
  };

  nextStep = () => {
    const { step } = this.state;
    const { vehicle, closeModal, createPersonalSaleReport } = this.props;
    let nextStep = '';
    switch (step) {
      case 'intro':
        nextStep = 'treatments';
        break;
      case 'treatments':
        nextStep = 'images';
        break;
      case 'images':
        nextStep = 'completed';
        break;
      case 'completed':
        createPersonalSaleReport(vehicle.id, {
          treatment_ids: this.state.selectedTreatments,
          image_id: this.state.selectedImage,
          token: this.state.token
        });
        closeModal();
        return;
    }
    this.setState({ step: nextStep });
  };

  previousStep = () => {
    const { step } = this.state;
    let previousStep = '';
    switch (step) {
      case 'treatments':
        previousStep = 'intro';
        break;
      case 'images':
        previousStep = 'treatments';
        break;
      case 'completed':
        previousStep = 'images';
        break;
    }
    this.setState({ step: previousStep });
  };

  copyUrlToClipboard = (text) => {
    const { t } = this.props;
    // Modern browsers with Clipboard API
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(function () {
        toast.success(t('linkCopied'));
      }).catch(function (err) {
        console.error('Could not copy text: ', err);
      });
    } else {
      // Fallback for older browsers
      var textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy');
        toast.success(t('linkCopied'));
      } catch (err) {
        console.error('Could not copy text', err);
      }

      document.body.removeChild(textarea);
    }
  }

  toggleTreatment = (treatment) => {
    const { selectedTreatments } = this.state;
    if (selectedTreatments.includes(treatment.id)) {
      this.setState({ selectedTreatments: selectedTreatments.filter(tId => tId !== treatment.id) });
    } else {
      this.setState({ selectedTreatments: [...selectedTreatments, treatment.id] });
    }
  }

  setImage = (image) => {
    const { selectedImage } = this.state;
    this.setState({ selectedImage: selectedImage == image.id ? null : image.id });
  }

  getSubmitText = () => {
    const { step } = this.state;
    const { vehicle } = this.props;

    const reportExists = !!vehicle.personal_sale_report_subscription;

    if (step === 'completed') {
      return reportExists ? 'Tallenna raportti' : 'Luo raportti';
    } else {
      return 'Seuraava';
    }
  }

  render() {
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      vehicle,
    } = this.props;

    const { step, selectedTreatments, selectedImage } = this.state;

    const reportExists = !!vehicle.personal_sale_report_subscription;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title={reportExists ? t('titleEditSaleReport') : t('titleCreateSaleReport')}
            descriptionText={t('description-step-' + step)}
            submitText={this.getSubmitText()}
            submitForm={this.nextStep}
            onCancel={this.previousStep}
            cancelText='Edellinen'
            hideCancelButton={!['treatments', 'images', 'completed'].includes(step)}
            isValid={true}
            vehicleText={vehicle && vehicle.registration_number}
            bothButtonsSubmitStyle
          >

            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              {
                step === 'intro' && (
                  <div>
                    <p>Intro</p>
                  </div>
                )
              }

              {
                step === 'treatments' && (
                  <div>
                    {
                      vehicle.treatments.map((treatment) => {
                        return (
                          <CBLabelAndCheckbox
                            key={treatment.id}
                            labelText={`${treatment.name} (${treatment.treatment_type_display}, ${formatDate(treatment.date)})`}
                            color='blue'
                            onChange={() => {this.toggleTreatment(treatment)}}
                            checked={selectedTreatments.includes(treatment.id)}
                          />
                        )
                      })
                    }
                    {
                      vehicle.treatments.length == 0 && (
                        <p style={getStyle(screenSize, styles, 'text')}>{t('noTreatmentsText')}</p>
                      )
                    }
                  </div>
                )
              }

              {
                step === 'images' && (
                  <div style={getStyle(screenSize, styles, 'thumbnailContainer')}>
                    {
                      vehicle.images.map((image) => {
                        return (
                          <CBSelectThumbnail
                            key={image.id}
                            src={image.image}
                            onClick={() => {this.setImage(image);}}
                            selected={selectedImage === image.id}
                          />
                        )
                      })
                    }
                    {
                      vehicle.images.length == 0 && (
                        <p style={getStyle(screenSize, styles, 'text')}>{t('noImagesText')}</p>
                      )
                    }
                  </div>
                )
              }

              {
                step === 'completed' && (
                  <div>
                    <p>Completed</p>
                  </div>
                )
              }
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px',
      color: '#000000',
    },
    thumbnailContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
    },
    reportButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 16,
    },
    text: {
      color: 'black',
      fontSize: 14,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
  }
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
  };
}

export default connect(
  mapStateToProps,
  {
    createPersonalSaleReport: _createPersonalSaleReport,
  },
)(translate('SaleReport')(PersonalSaleReportModal));
