import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import CBMediaQuery from '../shared/CBMediaQuery';

import { SERVICE_ADMIN, SERVICE_FLEET, formatDate, getStyle } from '../../utils/utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';


class SaleReportView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationNumber: '',
      phoneNumber: '',
      status: null,
      error: null,
    }
    this.pollingInterval = null;
  }

  fetchSaleReportStatus = async () => {
    const { service, selectedOrganizationId } = this.props;
    const { registrationNumber } = this.state;
    try {
      const data = {
        registration_number: registrationNumber,
      }

      if (service == SERVICE_FLEET && selectedOrganizationId) {
        data['organization_id'] = selectedOrganizationId;
      }

      const response = await axios.post(`/uiapi/1/vehicle_sale_report_status/`, data)
      this.setState({status: response.data, error: false, phoneNumber: response.data.sms_phone_number});
      this.handlePendingPermission(response.data);
    } catch (error) {
      this.setState({error: true, status: null});
    }
  }

  handlePendingPermission = (status) => {
    if (status && status.pending_permission) {
      this.setUpPolling();
    } else if(this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  registrationNumberChanged = (regNum) => {
    this.setState({registrationNumber: regNum})
  }

  phoneNumberChanged = (phoneNumber) => {
    this.setState({phoneNumber: phoneNumber})
  }

  requestSaleReportPermission = async () => {
    const { service, selectedOrganizationId } = this.props;
    const { registrationNumber, phoneNumber } = this.state;
    try {
      const data = {
        phone_number: phoneNumber,
        registration_number: registrationNumber,
      }

      if (service == SERVICE_FLEET && selectedOrganizationId) {
        data['organization_id'] = selectedOrganizationId;
      }

      const response = await axios.post(`/uiapi/1/sale_report_permission/`, data)
      this.setState({status: response.data, error: false, phoneNumber: response.data.sms_phone_number});
      this.handlePendingPermission(response.data);
    } catch (error) {
      this.setState({error: true});
    }
  }

  cancelSaleReportPermissionRequest = async () => {
    const { service, selectedOrganizationId } = this.props;
    const { registrationNumber, phoneNumber } = this.state;
    try {
      const data = {
        phone_number: phoneNumber,
        registration_number: registrationNumber,
        action: 'cancel',
      }

      if (service == SERVICE_FLEET && selectedOrganizationId) {
        data['organization_id'] = selectedOrganizationId;
      }

      const response = await axios.post(`/uiapi/1/sale_report_permission/`, data)
      this.setState({status: response.data, error: false, phoneNumber: response.data.sms_phone_number});
      this.handlePendingPermission(response.data);
    } catch (error) {
      this.setState({error: true});
    }
  }

  setUpPolling = () => {
    const { service, selectedOrganizationId } = this.props;
    const { registrationNumber } = this.state;
    const data = {
      registration_number: registrationNumber,
    }

    if (service == SERVICE_FLEET && selectedOrganizationId) {
      data['organization_id'] = selectedOrganizationId;
    }

    this.pollingInterval = setInterval(() => {
      axios.post(`/uiapi/1/vehicle_sale_report_status/`, data)
        .then((response) => {
          this.setState({status: response.data, error: false, phoneNumber: response.data.sms_phone_number});
          if (!response.data.pending_permission) {
            clearInterval(this.pollingInterval);
          }
        })
        .catch((error) => {
          this.setState({error: true});
          clearInterval(this.pollingInterval);
        });
    }, 5000);
  }

  render() {
    const { service, selectedOrganizationId, hasFeatureVehicleReports, user } = this.props;
    const { status, registrationNumber, error, phoneNumber } = this.state;

    const isOrganizationUse = service === SERVICE_FLEET && selectedOrganizationId;
    const isAdminUser = service === SERVICE_ADMIN && user.is_staff;

    let reportUrl = status ? `/reports/vehicle_sale_report/${status.vehicle_id}/` : '';

    if (isOrganizationUse) {
      reportUrl += `?organization_id=${selectedOrganizationId}`;
    }

    if (isAdminUser) {
      reportUrl += `?is_staff=true`;
    }

    if (!hasFeatureVehicleReports && !user.is_staff) {
      return (
        <div style={styles.default.welcomeTextContainer}>
        <p className="font-titillium-web-extra-light" style={styles.default.headerTextSub}>
          Et ole oikeutettu käyttämään ajoneuvoraporttia. Ota yhteyttä ylläpitoon.
        </p>
      </div>
      );
    }

    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <div style={getStyle(screenSize, styles, 'welcomeTextContainer')}>
              <p className="font-titillium-web-extra-light" style={styles.default.headerText}>
                Ajoneuvoraportti
              </p>
            </div>
            <div style={getStyle(screenSize, styles, 'welcomeTextSubContainer')}>
              <p className="font-titillium-web-extra-light" style={styles.default.headerTextSub}>
                Syötä rekisteritunnus ja hae raportti. Raportin avaamisesta ja luvan pyytämisestä peritään hinnaston mukainen maksu.
              </p>
            </div>
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <InputText
                  type="text"
                  className="p-inputtext-lg"
                  placeholder="ABC-123"
                  onChange={(e) => this.registrationNumberChanged(e.target.value)}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <Button
                  onClick={() => this.fetchSaleReportStatus(registrationNumber)}
                  label="Hae"
                  icon="pi pi-search"
                  size="large"
                />
              </div>
                {
                  status && (
                    <div style={getStyle(screenSize, styles, 'resultContainer')}>
                      <p>Ajoneuvolla on Ovio Carbookissa {status.maintenance_count} huoltotapahtumaa.</p>
                      <p>Viimeisin huolto on tehty {status.last_maintenance_pickup_date ? formatDate(status.last_maintenance_pickup_date) : '-'}.</p>
                      {
                        status.has_active_report && (
                          <p>Yrityksesi on jo hakenut raportin ajoneuvolle. Voit hakea raportin uudelleen maksutta {formatDate(status.active_report_expire_date)} asti.</p>
                        )
                      }
                      {
                        !status.needs_permission && (
                          <Button label="Siirry raporttiin" onClick={() =>  window.open(reportUrl, '_blank')}/>
                        )
                      }
                      {
                        status && status.needs_permission && (
                          <div style={getStyle(screenSize, styles, 'permissionContainer')}>
                            <div style={getStyle(screenSize, styles, 'permissionTextContainer')}>
                              <p className="font-titillium-web-semi-bold">
                                Tarvitset luvan ajoneuvon raportin hakemiseen. Pyydä lupaa raportin hakemiseen tekstiviestillä syöttämällä omistajan / haltijan puhelinnumero.
                              </p>
                            </div>

                            {
                              !status.pending_permission && (
                                <div>
                                  <div style={getStyle(screenSize, styles, 'inputContainer')}>
                                    <InputText
                                      type="text"
                                      className="p-inputtext-lg"
                                      placeholder="+3581234567"
                                      onChange={(e) => this.phoneNumberChanged(e.target.value)}
                                      disabled={status.pending_permission}
                                      value={phoneNumber || ''}
                                    />
                                  </div>
                                  <div style={getStyle(screenSize, styles, 'inputContainer')}>
                                    <Button
                                      onClick={() => this.requestSaleReportPermission(registrationNumber)}
                                      label="Pyydä lupaa"
                                      icon="pi pi-search"
                                      size="large"
                                      disabled={status.pending_permission}
                                    />
                                  </div>
                                </div>
                              )
                            }

                          </div>
                        )
                      }
                      {
                        status.pending_permission && (
                          <div style={getStyle(screenSize, styles, 'resultContainer')}>
                            <p>{`Lupa on pyydetty numerosta ${phoneNumber}. Odota vastausta.`}</p>
                            <div style={getStyle(screenSize, styles, 'inputContainer')}>
                              <Button
                                onClick={() => this.cancelSaleReportPermissionRequest(registrationNumber)}
                                label="Peruuta pyyntö"
                                icon="pi pi-times"
                                size="large"
                                severity="secondary"
                              />
                            </div>
                            <ProgressSpinner />
                          </div>
                        )
                      }
                    </div>
                  )
                }

                {
                  error && (
                    <div style={getStyle(screenSize, styles, 'resultContainer')}>
                      <p>Ajoneuvoa ei löytynyt palvelusta.</p>
                    </div>
                  )
                }

            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    resultContainer: {
      marginTop: 32,
      textAlign: 'center',
    },
    permissionContainer: {
      marginTop: 32,
    },
    permissionTextContainer: {
      marginBottom: 16,
    },
    inputContainer: {
      marginBottom: 16,
    },
    headerTextSub: {
      color: '#FFF',
      fontSize: 20,
      textAlign: 'center',
      margin: 0
    },
    headerText: {
      color: '#FFF',
      fontSize: 60,
      textAlign: 'center',
      margin: 0
    },
    welcomeTextContainer: {
      marginBottom: 16,
      marginTop: 32,
    },
    welcomeTextSubContainer: {
      marginBottom: 32,
    },
    bodyContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 32,
    },
    buttonContainer: {
      marginBottom: 16,
      minWidth: 280,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('SaleReportView')(SaleReportView));
